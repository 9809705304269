import React from 'react';
import { Link } from 'gatsby';
import ChriateImage from '../../components/ChriateImage';
import CallToAction from '../../modules/CallToAction/CallToAction';
import './Article.scss';

const Article = ( { data: { title, content, date, seo, slug, featuredImage, postFields: { author, callToAction } } } ) => {
  const isAuthor = author !== undefined && author !== null && author !== '';
  const isFeaturedImage = featuredImage !== undefined && featuredImage !== null && featuredImage.node !== null;
  const isCallToAction = callToAction !== undefined && callToAction !== null && callToAction !== '';
  return (
    <>
    <article className="article-post" asscroll="true">
      <div className="container">
        <div className="row">
          <div className="column article-post__head">
            <p className="article-post__subheading">
              News & Industry
            </p>
            <Link to="/journal" className="article-post__return btn">{`< back`}</Link>
            <h2 className="article-post__title">{title}</h2>
            <p className="article-post__date">{date}</p>
            {isAuthor && <p className="article-post__author">Written by <i>{author}</i></p>}
            {isFeaturedImage && <ChriateImage className="article-post__image" image={featuredImage.node} />}
          </div>
        </div>
        <div className="row">
          <div className="column">
            <div className="article-post__content">
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </div>
      </div>
    </article>
    {isCallToAction && <CallToAction backgroundColour="charcoal" text={callToAction} />}
    </>
   );
}

export default Article;


import React, { useState, useEffect } from "react";
import Seo from '../components/seo';
import Article from '../modules/Article/Article';
import ASScroll from '../components/ASScroll/ASScroll';
import { graphql, Link } from 'gatsby';

const JournalPost = ({ data: { wpPost } }) => {

  return (
    <>
      <ASScroll horizontalScroll={false} />
      <Seo seo={wpPost.seo} />
      <main className={`page page--journal slug-${wpPost.slug}`} asscroll-container="true">
        <Article data={wpPost} />
      </main>
    </>
  )
}

export const query = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      slug
      content
      date(formatString: "DD MMM YYYY")
      featuredImage {
        node {
          caption
          altText
          localFile {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        metaKeywords
        metaDesc
        metaRobotsNoindex
        title
      }
      postFields {
        author
        callToAction
      }
    }
  }
`;

export default JournalPost;